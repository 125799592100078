<template>
  <div>
    <nav class="navbar fixed-top navbar-expand-xl navbar-tplay">
      <router-link class="navbar-brand" to="/inicio">
        <img
          src="../assets/images/t-play_logo.svg"
          width="120"
          height="40"
          alt="T-Play Logo"
          :title="'Telecentro Play | Versión ' + version"
        />
      </router-link>

      <button
        @click.prevent="onCollapse()"
        class="navbar-toggler collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#tPlayMenu"
        aria-controls="tPlayMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="tPlayMenu">
        <ul class="navbar-nav mr-auto">
          <router-link tag="li" to="/inicio" class="nav-item" exact>
            <a class="nav-link">Inicio</a>
          </router-link>
          <router-link tag="li" to="/tv-en-vivo" class="nav-item" exact>
            <a class="nav-link">Tv en Vivo</a>
          </router-link>
          <router-link tag="li" to="/guia" class="nav-item" exact>
            <a class="nav-link">Guía</a>
          </router-link>
          <!-- <router-link tag="li" to="/guia-trad" class="nav-item" exact>
						<a class="nav-link">Guía Trad</a>
					</router-link> -->
          <router-link tag="li" to="/24-horas" class="nav-item">
            <a class="nav-link">24 Horas</a>
          </router-link>
          <router-link tag="li" to="/peliculas" class="nav-item" exact-path>
            <a class="nav-link">Películas</a>
          </router-link>
          <router-link tag="li" to="/series" class="nav-item" exact-path>
            <a class="nav-link">Series</a>
          </router-link>
          <router-link tag="li" to="/mi-contenido" class="nav-item" exact>
            <a class="nav-link">Mi contenido</a>
          </router-link>
        </ul>

        <CastIndicator
          v-if="deviceCastAvailable && isCastAvailable()"
          :friendlyName="friendlyName"
        />

        <form
          v-on:submit.prevent="onSearch()"
          class="form-inline my-2 my-lg-0 form-tplay--search"
        >
          <input
            id="search_input"
            v-model="search"
            class="form-control"
            type="search"
            value=""
            placeholder="¿Qué estás buscando?"
            aria-label="Buscar"
            @focus="onfocusSearch"
            @blur="onblurSearch"
          />
          <button class="btn btn-search my-2 my-sm-0" type="submit">
            Buscar
          </button>
        </form>

        <ul class="navbar-nav user-menu">
          <li class="nav-item dropdown">
            <a
              v-on:click.prevent="toggleDropdown($event)"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
            >
              <img
                src="../assets/images/person_icon.svg"
                alt="user"
                width="45"
                height="45"
              />
            </a>

            <div
              class="dropdown dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <p class="dropdown-item-text">
                <span class="user-name">{{ user.name }}</span
                ><br />
                <span class="user-client-number"
                  >Número de cliente: {{ user.clientId }}</span
                >
              </p>

              <div class="dropdown-divider"></div>

              <span class="dropdown-item-text disk-text"
                >Espacio en disco:</span
              >

              <div class="progress disk-space" :title="quotaTitle">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :aria-valuenow="user.quota"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="quotaWidth"
                ></div>
              </div>

              <div class="dropdown-divider"></div>

              <router-link
                tag="a"
                to="/control-parental"
                class="dropdown-item"
                title="Configuración del control parental"
                exact
              >
                <i class="zmdi zmdi-lock"></i>
                Control parental
              </router-link>

              <div class="dropdown-divider"></div>

              <a
                class="dropdown-item"
                v-on:click="masDetalle"
                href="https://telecentro.com.ar/sucursal-virtual/my-account"
                title="ir a Sucursal Virtual (nueva ventana)"
                target="_blank"
              >
                <i class="zmdi zmdi-arrow-right-top"></i>
                Más detalle
              </a>

              <div class="dropdown-divider"></div>

              <a
                v-on:click.prevent="logout"
                class="dropdown-item sign-out"
                href="#"
              >
                <i class="zmdi zmdi-sign-in"></i>
                Cerrar sesión
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import * as storeCast from "@/observables/cast";

export default {
  name: "Header",
  components: {
    CastIndicator: () => import("../components/CastIndicator.vue"),
  },

  data() {
    return {
      user: {
        name: "",
        clientId: 0,
        quota: 0,
      },
      search: "",
      deviceName: "",
      deviceCastAvailable: false,
      friendlyName: "",
      connected: false,
      eventSetted: false,
    };
  },

  watch: {
    search: function (newVal) {
      const self = this;
      const route = self.$router.history.current.name;

      const text = this.search.trim();

      // Corroborar que contenga mínimo 2 caracteres
      if (text.length >= 2) {
        document.querySelector(".form-tplay--search").classList.add("filled");
      } else {
        document
          .querySelector(".form-tplay--search")
          .classList.remove("filled");
      }

      if (route == "buscador") {
        setTimeout(function () {
          if (newVal == text) {
            self.onSearch();
          }
        }, 400);
      }
    },

    $route(to) {
      this.updateSearchText(to);
    },
  },

  created() {
    const self = this;

    self.user.name = telecentro.tplay.core.user.getName();

    self.user.clientId = telecentro.tplay.core.user.getClientId();

    self.updateQuota();

    self.$bus.$on("user:quota-change", self.updateQuota);
  },

  mounted() {
    const menu = document.querySelector(".navbar-tplay");
    const castInstance =
      typeof cast !== "undefined" && cast.framework
        ? cast.framework.CastContext.getInstance()
        : null;

    window.addEventListener("scroll", function () {
      const scrollY = window.scrollY;
      if (scrollY >= 30) {
        menu.classList.add("scrolled");
      } else {
        menu.classList.remove("scrolled");
      }
    });
    if (
      castInstance &&
      castInstance.S &&
      castInstance.S !== "NOT_CONNECTED" &&
      castInstance.S !== "CONNECTED"
    ) {
      this.deviceCastAvailable = false;
    } else this.deviceCastAvailable = true;
    this.updateSearchText(this.$router.history.current);
    if (typeof cast !== "undefined") {
      cast.framework.CastContext.getInstance().addEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        (event) => {
          const castState = event.castState;
          const session =
            cast.framework.CastContext.getInstance().getCurrentSession();
          console.info("status", castState);
          if (castState === "CONNECTING") return;
          if (castState === "NOT_CONNECTED" || castState === "DISCONNECTED") {
            const isMuted = window.tplay.ccast.remotePlayer.isMuted;
            this.connected = false;
            if (session) session.endSession();
            this.$bus.$emit("cast-removelisteners");
            this.$bus.$emit("set-cast-title", "");
            this.$bus.$emit("set-cast-subtitle", "");
            this.$bus.$emit("cast:connected", false, null, isMuted);
            this.deviceCastAvailable = false;
            this.friendlyName = "";
            if (castState === "NOT_CONNECTED") this.deviceCastAvailable = true;
            return;
          }
          this.deviceCastAvailable = true;
          if (castState === "CONNECTED") {
            if (!this.connected) {
              if (!this.eventSetted) this.$bus.$emit("cast-setevents");
              this.eventSetted = true;
              this.$bus.$emit("cast-removelisteners");
              this.$bus.$emit("cast-initlisteners");
              this.friendlyName = cast.framework.CastContext.getInstance()
                .getCurrentSession()
                .getCastDevice().friendlyName;
              this.$bus.$emit("cast:connected", true);
              this.$bus.$emit("cast-load", "START");
              const tplayCasAuthToken =
                telecentro.tplay.core.localStorageFake.getItem(
                  "tplayCasAuthToken"
                );
              telecentro.tplay.core.api.casVirtualId(
                tplayCasAuthToken,
                () => console.error("casvirtual id error"),
                () => console.info("OK --> casVirtualID")
              );
              this.$bus.$emit("cast-switchplayer");
              if (tplay.ccast.fromPlayer) {
                this.$bus.$emit("cast-setSourcePlayer");
              }
            }
            this.connected = true;
          }
        }
      );
    }
  },

  beforeDestroy() {
    this.$bus.$off("user:quota-change", this.updateQuota);
  },

  methods: {
    isCastAvailable() {
      return storeCast.haveSupport();
    },

    setupCastStateChangedListener() {
      const castContext = cast.framework.CastContext.getInstance();

      castContext.addEventListener(
        "caststatechanged",
        (event) => {
          const castState = event.castState;
          switch (castState) {
            case cast.framework.CastState.CONNECTED:
              this.deviceName = castContext
                .getCurrentSession()
                .getCastDevice().friendlyName;
              document.querySelector(".castName").style.display = "flex";
              break;
            case cast.framework.CastState.NOT_CONNECTED:
              document.querySelector(".castName").style.display = "none";
              break;
            default:
              break;
          }
        },
        false
      );
    },

    updateQuota() {
      const self = this;

      telecentro.tplay.core.npvr.obtenerQUOTA(function (ret) {
        const totalDisponible = ret.disponibleSegundos + ret.consumidoSegundos;
        self.user.quota = (ret.consumidoSegundos * 100) / totalDisponible;
      });
    },

    toggleDropdown(event) {
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: "Config", screen: this.$route.name },
        tplay.grafana.actions.ENTER
      );
      event.currentTarget.parentNode.classList.toggle("show");
      document
        .querySelector(".user-menu .dropdown-menu.dropdown-menu-right")
        .classList.toggle("show");
    },

    onfocusSearch() {
      // event.currentTarget.parentNode.classList.toggle('focus');
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: "Buscar", screen: this.$route.name },
        tplay.grafana.actions.ENTER
      );
      document
        .querySelector(".navbar-tplay .form-tplay--search .form-control")
        .classList.add("focus");
      document.querySelector(".castNav").classList.add("cast-mini");
      if (document.querySelector(".castName")) {
        document.querySelector(".castName").style.display = "none";
      }
    },
    onblurSearch() {
      // event.currentTarget.parentNode.classList.toggle('focus');
      document
        .querySelector(".navbar-tplay .form-tplay--search .form-control")
        .classList.remove("focus");
      document.querySelector(".castNav").classList.remove("cast-mini");
      if (document.querySelector(".castName")) {
        document.querySelector(".castName").style.display = "flex";
      }
    },

    logout() {
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: "LogOut", screen: this.$route.name },
        tplay.grafana.actions.ENTER
      );
      const self = this;
      self.$bus.$emit("show-loader", true);
      telecentro.tplay.core.logOutSync(
        () => {
          try {
            document.body.style.overflow = "hidden";
            tplay.endGrafanaSession();
          } catch (error) {
            console.error("Error:", error);
          }
          self.$router.replace({ path: "/login" });
        },

        (e) => {
          console.error("Error al hacer el logout", e);
        }
      );
    },

    masDetalle() {
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: "Más Detalle", screen: this.$route.name },
        tplay.grafana.actions.ENTER
      );
    },

    onSearch() {
      // Corroborar que contenga mínimo 2 caracteres
      if (this.search.length >= 2) {
        this.$router.replace({
          path: "/buscador/" + encodeURIComponent(this.search),
        });
      } else {
        this.$bus.$emit(
          "show-toast",
          "info",
          "Por favor, ingresá un texto de búsqueda con al menos dos caracteres."
        );
        document.querySelector('.form-tplay--search [type="search"]').focus();
      }
    },

    updateSearchText(route) {
      if (route.name === "buscador") {
        this.search = route.params.str;
      } else {
        this.search = "";
      }
    },

    onCollapse() {
      document.querySelector(".navbar-toggler").classList.toggle("collapsed");
      document
        .querySelector(".navbar-collapse.collapse")
        .classList.toggle("show");
    },
  },

  computed: {
    quotaTitle() {
      return "Espacio utilizado: " + Math.round(this.user.quota) + "%";
    },

    quotaWidth() {
      return "width:" + this.user.quota + "%";
    },

    version() {
      return process.env.VUE_APP_VERSION.replace(/(\d+\.\d+)\.\d+/, "$1");
    },
  },
};
</script>
